import axios from 'axios';
import { ICompany } from '../providers/auth';
import { COMPANY_ENDPOINTS } from './constants';

export const getCompanies = async () => {
  try {
    const { data } = await axios.get(COMPANY_ENDPOINTS().GET_COMPANIES);

    return data.result as ICompany[];
  } catch (e) {
    throw e;
  }
};

export const getTemplatesByCompany = async (companyId: string) => {
  try {
    const { data } = await axios.get(
      COMPANY_ENDPOINTS().GET_TEMPLATES_BY_COMPANY(companyId),
    );

    return data.result;
  } catch (e) {
    throw e;
  }
};

export const updateLogo = async (companyId: string, logo: File) => {
  try {
    const formData = new FormData();
    formData.append('companyId', companyId);
    formData.append('logo', logo);

    return await axios.post(COMPANY_ENDPOINTS().ADD_LOGO, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw error;
  }
};

interface ICreateCompany {
  name: string;
  email: string;
  type: string;
  industry: string;
  fromInvestmentCompany: string;
}

export const createCompany = async (company: ICreateCompany) => {
  try {
    const {
      data: {
        result: { companyId },
      },
    } = await axios.post(COMPANY_ENDPOINTS().CREATE_COMPANY, company);

    await axios.post(COMPANY_ENDPOINTS().ADD_USER_TO_COMPANY, {
      companyId,
      users: [
        '5c13aa906dcb6',
        '5caef021ef4d1',
        '5caef04dc409e',
        '5f71f8d030446',
        '618bc965c50bc',
        '61fd3c26a47d5',
        '628e00300da98',
        '63072b20b15dd',
        '632c198a0d146',
        '6343ea971ba9d',
        '6344074135477',
        '6344075a8f0a9',
        '634407735ea5d',
        '63db7cd535826',
      ],
    });
  } catch (e) {
    throw e;
  }
};
