import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useStores } from '../../stores/hooks/useStores';
import { createCompany } from '../../api/CompanyAPI';
import { toast } from 'react-toastify';

const NewCompanyForm = () => {
  const { t } = useTranslation();

  const {
    companyStore: { newCompanyForm, setNewCompanyForm, getCompanies },
  } = useStores();

  const validationSchema = Yup.object({
    name: Yup.string().required(
      t('common.fieldIsRequired') || 'Field is required!',
    ),
    email: Yup.string()
      .email(t('common.invalidEmail') || 'Email is invalid')
      .required(t('common.fieldIsRequired') || 'Field is required!'),
    type: Yup.string().required(
      t('common.fieldIsRequired') || 'Field is required!',
    ),
    industry: Yup.string().required(
      t('common.fieldIsRequired') || 'Field is required!',
    ),
    fromInvestmentCompany: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      type: '',
      industry: '',
      fromInvestmentCompany: false,
    },
    validationSchema,
    onSubmit: (values) => {
      createCompany({
        ...values,
        fromInvestmentCompany: String(values.fromInvestmentCompany),
      });

      toast.success(t('common.companyCreatedSuccessfully'));

      setNewCompanyForm(false);

      getCompanies();
    },
  });

  if (!newCompanyForm) return null;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 20,
        }}
      >
        <Grid item xs={6}>
          <TextField
            label={t('common.name')}
            variant="outlined"
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('common.email')}
            variant="outlined"
            fullWidth
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('common.type')}
            variant="outlined"
            fullWidth
            name="type"
            value={formik.values.type}
            onChange={formik.handleChange}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={t('common.industry')}
            variant="outlined"
            fullWidth
            name="industry"
            value={formik.values.industry}
            onChange={formik.handleChange}
            error={formik.touched.industry && Boolean(formik.errors.industry)}
            helperText={formik.touched.industry && formik.errors.industry}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="fromInvestmentCompany"
                checked={formik.values.fromInvestmentCompany}
                onChange={formik.handleChange}
              />
            }
            label={t('common.fromInvestmentCompany')}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default observer(NewCompanyForm);
